import request from './request';

// 1. 数字人
export const chatLogin = (data) => {
    return request({
        url: '/loginBySecret',
        method: 'post',
        data:data
    })
}


// 1. 数字人
export const chatChat = (data) => {
    return request({
        url: '/chat',
        method: 'post',
        data:data
    })
}