<template>
  <div class="dazz">
    <div class="danmuxianshi" v-if="showJiaoHuBtn">
      <span class="kaishijianhu" @click="playSV">开始交互</span>
    </div>
    <div id="showcontent"></div>
    <div class="mainBox" @click="huanzhengqu">
      <div>
        <img src="../assets/homeicon.png" alt="" />
      </div>
      <span>换证</span>
    </div>
    <div class="contens">
      <div class="content" ref="scrollContainer">
        <div
          class=""
          v-for="(item, index) in lists"
          :key="index"
          :class="[
            item.type == 'user' ? 'right_class mossz' : 'left_class mossz',
          ]"
        >
          <img
            :src="item.type == 'user' ? userimg : sysimg"
            mode=""
            class="headss"
          />

          <div class="cons">
            <img
              :src="item.type == 'user' ? userbg : sysbg"
              mode=""
              class="msgbg"
            />
            <div :class="[item.type == 'user' ? '' : 'letkszza',]">
              
           {{ item.content }}</div>
          </div>
        </div>
      </div>
      <div class="bommoos">
        <div class="c_cens">
          <div class="yuyin" v-if="voiceMsg">
            <img
              src="../assets/video.png"
              mode=""
              class="b_left"
              @click="changeToVoice"
            />
            <div
              class="anniuxianshi"
              @touchstart.stop="startVocie"
              @touchend.stop="endVoice"
              :class="[if_statrt_voice?'titttt':'']"
            >
              <!-- <span v-if="if_statrt_voice" class="titttt">按住说话</span> -->
              <div class="boolangxianshi" v-if="!if_statrt_voice">
                <div class="music">
                  <div class="item_aza one"></div>
                  <div class="item_aza two"></div>
                  <div class="item_aza three"></div>
                  <div class="item_aza four"></div>
                  <div class="item_aza five"></div>
                  <div class="item_aza six"></div>
                  <div class="item_aza seven"></div>
                </div>
              </div>
            </div>
            <img
              src="../assets/homemsg.png"
              mode=""
              class="right ghhf"
              @click="changeToText"
            />
          </div>

          <div class="wenzi" v-else>
            <img
              src="../assets/video.png"
              mode=""
              class="b_left"
              @click="changeToVoice"
            />
            <div class="shurukuang">
              <input placeholder="请输入...." v-model="value" class="inpuss" />
            </div>
            <div class="fasongganniu" @click="fasongfun">
              <span>发送</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../../public/cmwwplayer.umd.cjs'></script>
<script>
import CryptoJS from "crypto-js";
import wx from "weixin-js-sdk";
// import cmwwPlayer from "../../public/cmwwplayer.umd.cjs";

import { chatLogin, chatChat } from "../elseapi/api";

export default {
  name: "App",
  data() {
    return {
      showJiaoHuBtn: false,
      inputFile: "",
      selectFileRes: null,
      env: "prd",
      url: "https://aiask365.com",
      // form: {
      //   token: "b0779d8daf5e7129b6dbf6ac05fb229c",
      //   projectId: "1734120128225873920",
      //   question: "这是第几个问题",
      //   answer: `出门问问是一家以生成式AI和语音交互为核心的人工智能公司，为全球多个国家提供面向创作者的AIGC工具、AI政企服务，以及AI智能硬件。`,
      // },
      form: {
        token: localStorage.getItem("token"),
        projectId: localStorage.getItem("projectId"),
      },
      disabled: true,
      curVideo: false,
      asrStatus: false,

      userimg: require("../assets/headuser.png"),
      sysimg: require("../assets/headsys.png"),
      userbg: require("../assets/vmbg.png"),
      sysbg: require("../assets/sysbg.png"),
      lists: [
        // { type: 'user', content: '我们一般需要3个工作日审核。' },
        // { type: 'sys', content: '我们一般需要3个工作日审核。流程审核麻烦吗，如果中间有 不对再次审核时间久吗？' },
        //  { type: 'user', content: '我们一般需要3个工作日审核。' },
        // { type: 'sys', content: '我们一般需要3个工作日审核。流程审核麻烦吗，如果中间有 不对再次审核时间久吗？' },
        //  { type: 'user', content: '我们一般需要3个工作日审核。' },
        // { type: 'sys', content: '我们一般需要3个工作日审核。流程审核麻烦吗，如果中间有 不对再次审核时间久吗？' },
      ],
      voiceMsg: true,
      value: "",
      if_statrt_voice: true,
      showJiaoHuBtn: false,

      chat_infos: {},
      receivedData: "",
      touchTimer:''
    };
  },
  watch: {
    lists: {
      handler() {
        this.scrollToBottom();
      },
      deep: true
    }
  },
  created() {
    this.login_chat();
  },
  mounted() {
    setTimeout(() => {
      this.onSubmit();
      this.asrInit();
    }, 1000);
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.scrollContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    huanzhengqu(){
      wx.miniProgram.postMessage({
        data: JSON.stringify({
          action: 'postData',
          data: 'BASE 64 IMAGE STRING'
        })
      });
      wx.miniProgram.navigateBack()
    },
    fasongfun() {
      if (!this.value) {
        return;
      }
      const titla = this.value;
      this.value = "";
      this.chat_chat_chat(titla);
      let mhs = { type: "user", content: titla };
      this.lists.push(mhs);
    },
    login_chat() {
      const account = "18625535313";
      const appsect = "034AB437B312412A952238B32805E2EA";
      let time = parseInt(new Date().getTime() / 1000) + "";
      let ssa = `${account}+${appsect}+${time}`;

      const hash = CryptoJS.MD5(ssa);

      // 将结果转换为 16 进制字符串
      const hexString = hash.toString(CryptoJS.enc.Hex);
      let sds = {
        account: account,
        signature: hexString,
        timestamp: time,
      };
      // let toks = localStorage.getItem('chat_token')
      // if(toks){
      //   return
      // }
      chatLogin(sds).then((res) => {
        console.log(res);
        if (res.message == "OK") {
          localStorage.setItem("chat_token", res.data.token);
          localStorage.setItem("chat_ID", res.data.companyId);
          // this.set_number_peoople()
          this.chat_infos = res.data;
        } else {
          alert("授权失败");
        }
      });
    },
    chat_chat_chat(val) {
      // this.fetchStreamData(val)
      // return
      let time = parseInt(new Date().getTime() / 1000) + "";
      let smz = {
        roleId: "1833405236501417984",
        query: val,
        language: "zhchs",
        sessionId: time,
      };
      chatChat(smz).then((res) => {
        console.log(res);
        // let alks = Object.values(res)
        // console.log(alks)
        // 分割字符串，提取每个数据块
        const chunks = res
          .split("\n")
          .filter((line) => line.startsWith("data:"))
          .map((line) => JSON.parse(line.slice(5)));

        chunks.map((v) => {
          this.handleDataStream(v);
        });
      });
    },
    handleDataStream(chunk) {
      // 将新接收的数据追加到累积变量中
      this.receivedData += chunk.content;
      console.log(chunk);
      console.log("shuju");
      // 检查是否完成了数据流
      if (chunk.finish_reason === "stop") {
        this.processCompleteData();
      }
    },
    processCompleteData() {
      // 在这里处理完整的数据
      console.log("Complete data:", this.receivedData);
      this.sendQA(this.receivedData);

      // 如果需要解析为特定的格式，可以在这里进行转换
      // 例如，如果最终结果应该是一个数字，可以使用 parseInt 或 parseFloat
      const result = parseFloat(this.receivedData);
      console.log("Result as number:", result);
    },
    sendQA(val) {
      this.sdkPlayer.interrupt();
      // this.sdkPlayer.playQA(this.form.question, this.form.answer);
      // this.sdkPlayer.playStreamText(val)
      this.sdkPlayer.playSilentvideo();
      console.log(val)
      this.sdkPlayer.playQA('', val);
      // this.sdkPlayer.playSilentvideo();
      let mhs = { type: "sys", content: val };
      this.lists.push(mhs);
      this.receivedData = "";
    },
    playSV() {
      this.sdkPlayer.playSilentvideo();
      this.showJiaoHuBtn = false;
      this.chat_chat_chat('');
    },
    kkssz() {
      // wx.miniProgram.postMessage({
      //   data: JSON.stringify({
      //     action: 'postData',
      //     data: 'BASE 64 IMAGE STRING'
      //   })
      // });
      // wx.miniProgram.navigateBack()
    },
    changVideototext() {
      this.voiceMsg = false;
    },
    asrStart() {
      this.asrClient.asrStart();
    },
    asrStop() {
      this.asrClient.asrStop();
    },
    asrInit() {
      const that = this;
      this.asrClient = cmwwPlayer.asrClient({
        _projectId: that.form.projectId,
        token: that.form.token,
        host: that.url,
        cb: (val) => {
          console.log(val);
          if (val.type == "asr-part") {
            console.log(val.content);
          } else if (val.type == "asr") {
            console.log(val.content);
            that.chat_chat_chat(val.content);
            let mhs = { type: "user", content: val.content };
            that.lists.push(mhs);
          }
        },
      });
    },
    generateRandomNumber32Bits() {
      return (Math.random().toString(16) + "00000000000000000000").slice(2, 34);
    },
    onSubmit() {
      console.log(this.generateRandomNumber32Bits().length);
      cmwwPlayer.cmwwCli(
        {
          environment: this.env,
          containerLable: "#showcontent",
          // host: this.url,
          token: this.form.token,
          projectId: this.form.projectId,
          zoom: 0.3,
          quality: 1,
          clientId: this.generateRandomNumber32Bits(),
          asr: false,
        },
        (res) => {
          //成功回调
          this.sdkPlayer = res;
          console.log(res);
          this.sdkPlayer.on("intialSucccess", (res) => {
            // console.log('浏览器UA---->',window.navigator.userAgent)

            if (res.type == "1") {
              // 开始播放
              // console.log('111111')
              this.sdkPlayer.startPlay();
            } else if (res.type == "2") {
              // 出弹框
              console.log(res);
              this.showJiaoHuBtn = true;
            }
            // alert('初始化成功')
          });
          this.sdkPlayer.on("intialError", (err) => {
            console.log(err);
            alert("初始化失败：" + err);
          });
          this.sdkPlayer.on("message", (res) => {
            console.log("res---->", res, Date.now());
            if (res.type == "videoStart") {
              this.disabled = false;
            } else if (res.type == "videoEnd") {
              this.disabled = true;
            }
          });
          this.sdkPlayer.init();
        },
        (err) => {
          //失败回调
          alert("失败回调：" + err);
        }
      );
    },
    changeToVoice() {
      this.voiceMsg = true;
    },
    changeToText() {
      this.voiceMsg = false;
      this.if_statrt_voice = true;
    },

    startVocie(e) {
      console.log("开始进行");
      e.preventDefault()
      this.if_statrt_voice = false;
      this.asrStart();
    },
    endVoice() {
      console.log("结束");
      this.if_statrt_voice = true;
      clearTimeout(this.touchTimer);
      this.asrStop();
    },
  },
};
</script>

<style scoped>
.dazz {

  display: flex;
  position: relative;
  background: #f4f4f4;
  background-image: url("https://wenyan-oss.chumenwenwen.com/wanyan-avatar/1678662347998236672/7fba7f9f625e4e7682928f28780c47e7.png");
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.danmuxianshi {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.kaishijianhu {
  width: 200px;
  height: 100px;
  color: #fff;
  background: #00aaff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#showcontent {
  flex: 1;
}

.contens {
  position: absolute;
  height: 60%;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow: auto;
}
.content::-webkit-scrollbar {
  display: none; /* 隐藏滚动条 */
}

.content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.content > .mossz:first-child {
  margin-top: auto;
}

.bommoos {
  height: 200px;
  width: calc(100% - 60px);
  overflow: hidden;
  padding: 0 30px;
}

.right_class {
  width: 100%;
  display: flex;
  justify-content: end;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 20px;
  align-items: flex-start;
}

.left_class {
  display: flex;
  align-items: center;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 20px;
}

.headss {
  width: 90px;
  height: 90px;
}

.cons {
  position: relative;
  padding: 22px 0;
  min-width: 300px;
  max-width: 600px;
}

.cons > div {
  position: relative;
  z-index: 9;
  padding-left: 40px;
  padding-right: 30px;
}
.letkszza{
  padding-left: 70px!important;
}

.msgbg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.c_cens {
  margin-top: 30px;
  background: #ffffff;
  padding: 15px 22px;
  border-radius: 44px;
}

.yuyin {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.b_left {
  width: 58px;
  height: 58px;
  background: #e1e1e1;
  border-radius: 50%;
}

.anniuxianshi {
  width: 603px;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e1e1e1;
  border-radius: 30px;
}

.titttt {
  font-size: 32px;
  color: #303133;
}
.titttt::before{
  content: '按下说话';
}

.boolangxianshi {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right {
  width: 50px;
  height: 42px;
}

.wenzi {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shurukuang {
  flex: 1;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.inpuss {
  width: 100%;
  outline: none;
  border: 0px;
}

.b_left {
  width: 58px;
  height: 58px;
  background: #e1e1e1;
  border-radius: 50%;
}

.fasongganniu {
  color: #fff;
  background: #2979fc;
  font-size: 28px;
  padding: 8px 12px;
  border-radius: 6px;
}

.mainBox {
  position: absolute;
  width: 182px;
  height: 88px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.19);
  z-index: 999;
  right: 0;
  top: 40%;
  display: flex;
  align-items: center;
  border-radius: 44px 0 0 44px;
}
.mainBox > div {
  width: 90px;
  height: 90px;
  background: #ffffff;
  border-radius: 44px;
  border: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainBox > div >img{
  width: 56px;
height: 44px;
}
.mainBox>span{
  font-family: PingFang SC;
font-weight: 500;
font-size: 34px;
color: #333333;
}

.music {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.item_aza {
  position: absolute;
  width: 8px;
  border-radius: 6px;
  background-color: #1f94ea;
}

@keyframes radius-animation {
  100% {
    height: 10px;
    border-radius: 50%;
    filter: contrast(2);
  }
}

.music .one {
  height: 18px;
  transform: translateX(-60px);
  animation: radius-animation 0.3s ease;
}

.music .two {
  height: 53px;
  transform: translateX(-40px);
  animation: radius-animation 0.6s ease;
}

.music .three {
  height: 36px;
  transform: translateX(-20px);
  animation: radius-animation 0.57s ease;
}

.music .four {
  height: 70px;
  transform: translateX(0);
  animation: radius-animation 0.52s ease;
}

.music .five {
  height: 30px;
  transform: translateX(20px);
  animation: radius-animation 0.4s ease;
}

.music .six {
  height: 40px;
  transform: translateX(40px);
  animation: radius-animation 0.3s ease;
}

.music .seven {
  height: 50px;
  transform: translateX(60px);
  animation: radius-animation 0.7s ease;
}

.music .item_aza {
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-direction: alternate;
  -webkit-animation-direction: alternate;
}
</style>