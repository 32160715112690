import Vue from 'vue'
import VueRouter from 'vue-router'
import Webview from '../views/step.vue'
import ReadyPage from '../views/reset_page.vue'
import Nextpage from '../views/stepone_page.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'readypage',
    component: ReadyPage
  },
  {
    path: '/webview',
    name: 'webview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Webview
  },
  {
    path: '/nextpage',
    name: 'nextpage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Nextpage
  }
]

const router = new VueRouter({
  routes
})

export default router
