import request from './request';

// 1. 登录
export const merchantLogin = (data) => {
    return request({
        url: '/login',
        method: 'post',
        data:data
    })
}

// 1. 数字人
export const setProject = (data) => {
    return request({
        url: '/setProject',
        method: 'post',
        data:data
    })
}


// 1. 数字人
export const chatLogin = (data) => {
    return request({
        url: '/loginBySecret',
        method: 'post',
        data:data
    })
}


// 1. 数字人
export const chatChat = (data) => {
    return request({
        url: '/chat',
        method: 'post',
        data:data
    })
}