<template>
    <div>
        <span @click="logoin">sdsadasdas</span>
    </div>
</template>

<script>
import { merchantLogin,setProject } from '../api/api'
import CryptoJS from "crypto-js";
export default {
    data() {
        return {
            AppKey: '02ACC9599941B5FDD7FCBB599B5A8DD1',
            AppSecret: '022625B161F91CEBCA938041C858AC5A',
            tokens: '',
            roleID:'1833405236501417984'
        }
    },

    created() {
     
        this.logoin()

    },
    methods: {
        logoin() {
            let time = parseInt(new Date().getTime() / 1000) + '';
            let ssa = `${this.AppKey}+${this.AppSecret}+${time}`
            const hash = CryptoJS.MD5(ssa);

            // 将结果转换为 16 进制字符串
            const hexString = hash.toString(CryptoJS.enc.Hex);
            let datas = {
                appkey: this.AppKey,
                signature: hexString,
                timestamp: time
            }
            merchantLogin(datas).then(res => {
                console.log(res)
                this.tokens = res.data
               
                localStorage.setItem('token',res.data)
                if (res.data) {
                    
                    this.set_number_peoople()
                }else{
                    alert('授权失败')
                }
            })
        },

        set_number_peoople(){
            let ops = {
                appkey:this.AppKey,
                speed:'1.1',
                avatarSpeakerId:'linlin_wen_6_808_512_1080p',
                ttsSpeakerId:'cissy_meetfast_24k'
            }
            setProject(ops).then(res=>{
                console.log(res)
                if(res.data){
                    localStorage.setItem('projectId',res.data)
                    this.$router.push({
                        path: '/webview'
                    })
                }else{
                    alert('初始化失败')
                }
                
            })
        }

       
    }
}

</script>
<style></style>