<template>
    <div>
        2222
    </div>
    
</template>

<script>
export default{


    created(){
        
    }
}

</script>