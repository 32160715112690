/****   request.js   ****/
import axios from 'axios'




//配后端数据的接收方式application/json;charset=UTF-8或者application/x-www-form-urlencoded;charset=UTF-8
const contentType = "application/json;charset=UTF-8"

const elType = 'application/x-www-form-urlencoded;charset=UTF-8'

//1. 创建新的axios实例
const service = axios.create({
  // baseURL: location.origin + '/okr', // 公共接口-生产
  // baseURL: ' https://kkm.kaigekeji.com:7081/kaka', // 公共接口-本地
  baseURL: 'https://aiask365.com/avatar/openApi', // 公共接口-本地
  timeout: 30 * 1000, // 超时时间单位是ms
  headers: {
    "Content-Type": contentType,
  },
})

// 2.请求拦截器
service.interceptors.request.use(config => {
  //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加

  //注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
  console.log(config)
  const method = config.method.toLowerCase()
  let data = config.data
  let ids = localStorage.getItem('id')
  if(ids){
    config.headers.admin = ids
  }
  let toks = localStorage.getItem('token')
  if(toks){
    config.headers.token = toks
  }
  if (method === 'get') {
    let paramsUrl = '?'
    for (let i in data) {
      if (data[i] !== undefined) {
        if (data[i] instanceof Array) {
          data[i].map(item => {
            paramsUrl += `${i}[]=${item}&`
          })
        } else {
          paramsUrl += `${i}=${data[i]}&`
        }
      }
    }
   console.log( paramsUrl.substring(0, paramsUrl.length - 1))
   config.url = config.url + paramsUrl

  }
  return config;
}, error => {
  return Promise.reject(error);
})

// 3.响应拦截器
service.interceptors.response.use(response => {
  const {
    data,
    config
  } = response;
  return data
}, error => {
  console.log(error)
  if (error && error.response) {
    // 1.公共错误处理
    // 2.根据响应码具体处理
    switch (error.response.status) {
      case 400:
        error.message = '错误请求'
        break;
      case 401:
        error.message = '未授权，请重新登录'
        break;
      case 403:
        error.message = '拒绝访问'
        break;
      case 404:
        error.message = '请求错误,未找到该资源'
        break;
      case 405:
        error.message = '请求方法未允许'
        break;
      case 408:
        error.message = '请求超时'
        break;
      case 500:
        error.message = '服务器端出错'
        break;
      case 501:
        error.message = '网络未实现'
        break;
      case 502:
        error.message = '网络错误'
        break;
      case 503:
        error.message = '服务不可用'
        break;
      case 504:
        error.message = '网络超时'
        break;
      case 505:
        error.message = 'http版本不支持该请求'
        break;
      default:
        error.message = `连接错误${error.response.status}`
        break;
    }
  } else {
    // 超时处理
    if (JSON.stringify(error).includes('timeout')) {
      console.log('服务器响应超时，请刷新当前页')
    }
    console.log('连接服务器失败')
  }
  return Promise.reject(error);
})

//4.导出文件
export default service